<template>
  <div class="cost">
    <Header back="返回" title="研发项目" index="首页" titleOne="科创模块" titleTwo="研发项目" beforeTitle="研发验收报告" />
    <div class="content">
      <h1>研发验收报告</h1>
      <el-form
        :rules="formRule"
        ref="formRule"
        :model="form"
        :label-position="labelPosition"
        label-width="80px"
      >
        <el-form-item label="项目名称" prop="rd_name">
          <el-input v-model="form.rd_name"></el-input>
        </el-form-item>
        <el-form-item label="项目开始时间" prop="start_time">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            v-model="form.start_time"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="项目结束时间" prop="end_time">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            v-model="form.end_time"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="项目负责人">
          <el-input v-model="form.charge_person"></el-input>
        </el-form-item>
        <el-form-item label="项目实施情况" style="width: 100%">
          <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="form.implement"></el-input>
        </el-form-item>
        <el-form-item label="项目验收意见" style="width: 100%">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="请输入内容"
            v-model="form.acceptance_comments"
          ></el-input>
        </el-form-item>
        <el-form-item label="项目审核意见" style="width: 100%">
          <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="form.audit_opinion"></el-input>
        </el-form-item>
      </el-form>
      <div class="foot_TJ">
        <el-button type="primary" :loading="btnLoading" @click="onSubmit" :disabled="isDisable">确定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      btnLoading: false,//提交按钮动画
      isDisable: false,
      labelPosition: "top",
      form: {
        rd_name: "",
        start_time: "",
        end_time: "",
        charge_person: "",
        implement: "",
        acceptance_comments: "",
        audit_opinion: "",
      },
      formRule: {
        rd_name: [
          {
            required: true,
            message: "请输入项目名称",
            trigger: "blur",
          },
        ],
        start_time: [
          {
            required: true,
            message: "请选择项目开始时间",
            trigger: "change",
          },
        ],
        end_time: [
          {
            required: true,
            message: "请选择项目结束时间",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    meber_id () {
      return this.$route.query.id;
    },
  },
  mounted () {
    if (this.meber_id) {
      this.axios
        .get("/api/rd/view", {
          params: {
            id: this.meber_id,
          },
        })
        .then((res) => {
          this.form = res.data;
        });
    }
  },
  methods: {
    onSubmit () {
      this.$refs.formRule.validate((vaild) => {
        this.com_id = this.form.com_id;
        if (vaild) {
          this.btnLoading = true
          this.axios
            .post("/api/rd/store", this.form)
            .then((res) => {
              this.$message({
                type: "success",
                message: "保存成功!",
              });
              this.$router.push("/rd/index");
              this.isDisable = true;
              this.btnLoading = false
            })
            .catch((res) => {
              console.log("err:", res);
              this.btnLoading = false
            });
        } else {
          this.$message.error("");
        }
      });
    },
  },
};
</script>

<style scoped="scoped">
::v-deep.el-form-item {
  width: 23%;
  display: inline-block;
  margin-right: 2%;
}

.el-select {
  width: 100%;
}

.ImportTop span {
  color: #a6abc7;
}
</style>
